import { navigate } from 'gatsby-plugin-intl';
import React, { useEffect } from 'react';

const Explore: React.FC<WebPageProps> = () => {
  useEffect(() => {
    navigate('/explore/openings');
  }, []);

  return null;
};

export default Explore;
